<template>
  <div class="buildPlan">
    <NavBar :navSelIndex="1"></NavBar>
    <!-- 轮播图 -->
    <b-carousel
      id="carouselExampleControls"
      :interval="4000"
      controls
      indicators
      background="#ababab"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="item in banners"
        v-bind:key="item.banner_path"
        :img-src="item.banner_path"
      >
      </b-carousel-slide>
    </b-carousel>
    <div class="a0">
      <b-row class="b0">
        <b-col class="c0" sm="3" md="3" lg="2" xl="2">
          <img src="../../../public/icon/fga3.png" alt="" />
          <span>面积</span>
        </b-col>
        <b-col sm="9" md="9" lg="10" xl="10">
          <b-row class="c1">
            <div
              class="d0"
              :class="item.min == min && item.max == max ? 'active' : ''"
              @click="chooseType(item, 'area')"
              v-for="(item, index) in areaList"
              :key="index"
            >
              {{ item.name }}
            </div>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="b0">
        <b-col class="c0" sm="3" md="3" lg="2" xl="2">
          <img src="../../../public/icon/fga2.png" alt="" />
          <span>户型</span>
        </b-col>
        <b-col sm="9" md="9" lg="10" xl="10">
          <b-row class="c1">
            <div
              class="d0"
              :class="item.id == houseType ? 'active' : ''"
              @click="chooseType(item, 'door')"
              v-for="(item, index) in doorList"
              :key="index"
            >
              {{ item.name }}
            </div>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="b0">
        <b-col class="c0" sm="3" md="3" lg="2" xl="2">
          <img src="../../../public/icon/fga1.png" alt="" />
          <span>风格</span>
        </b-col>
        <b-col sm="9" md="9" lg="10" xl="10">
          <b-row class="c1">
            <div
              class="d0"
              :class="item.LABEL_ID == styleId ? 'active' : ''"
              @click="chooseType(item, 'style')"    
              v-for="(item, index) in styleList"
              :key="index"
            >
              {{ item.LABEL_NAME }}
            </div>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="a01">
      <b-row
        class="b0"
        v-for="(item, index) in buildingList"
        :key="index"
        @click="goBuildDetail(item.buildId)"
      >
        <b-col class="c0" sm="12" md="12" lg="12" xl="6">
          <img class="d0" :src="item.coverUrl" alt="" />
        </b-col>
        <b-col class="c1" sm="12" md="12" lg="12" xl="6">
          <div class="d0">
            <div class="e0">
              <span class="f0">{{ item.buildName }}</span>
              <span class="f1"
                >{{ item.measureMin }}-{{ item.measureMax }}㎡</span
              >
            </div>
            <div class="e1">
              <img
                v-if="item.haveThreeD == 1"
                class="f0"
                src="../../../public/icon/a13.png"
                alt=""
              />
              <img
                v-if="item.haveTwoD == 1"
                class="f1"
                src="../../../public/icon/a6.png"
                alt=""
              />
            </div>
          </div>
          <div class="d1">
            <!-- <span class="e0">
              {{item.buildIntroduce}} 
            </span> -->
            <span class="e1" v-html="item.buildIntroduce"> </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Right from "@/components/Right.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    NavBar,
    Right,
    Footer,
  },
  data() {
    return {
      banners: [],
      areaList: [
        {
          name: "不限",
          min: "",
          max: "",
        },
        {
          name: "50-70㎡",
          min: "50",
          max: "70",
        },
        {
          name: "70-90㎡",
          min: "70",
          max: "90",
        },
        {
          name: "90-110㎡",
          min: "90",
          max: "110",
        },
        {
          name: "110-130㎡",
          min: "110",
          max: "130",
        },
        {
          name: "130-150㎡",
          min: "130",
          max: "150",
        },
        {
          name: "150㎡以上",
          min: "150",
          max: "",
        },
      ],
      doorList: [
        {
          name: "不限",
          id: "",
        },
        {
          name: "一居",
          id: "1",
        },
        {
          name: "两居",
          id: "2",
        },
        {
          name: "三居",
          id: "3",
        },
        {
          name: "四居",
          id: "4",
        },
        {
          name: "五居",
          id: "5",
        },
        {
          name: "五居以上",
          id: "6",
        },
      ],
      styleList: [
        {
          LABEL_ID: "",
          LABEL_NAME: "不限",
        },
      ],
      buildingList: [], //数据列表
      min: "", //价格最小
      max: "", //价格最大
      houseType: "", //户型
      styleId: "", //风格
      pageNum: 1, //页码
      keyWords: "",
    };
  },
  mounted() {
    if (this.$route.query.keyWords) {
      this.keyWords = this.$route.query.keyWords;
    }
    this.getStyles();
    this.getBuildingList();
    this.getBanners();
  },
  methods: {
    goBuildDetail(id) {
      this.$router.push({ path: "/projectDetail", query: { id: id } });
    },
    getBanners() {
      const that = this;
      that.$api
        .banners({
          banner_type: 2,
        })
        .then((res) => {
          that.banners = res.data;
        });
    },
    chooseType(item, type) {
      switch (type) {
        case "area":
          this.min = item.min;
          this.max = item.max;
          break;
        case "door":
          this.houseType = item.id;
          break;
        case "style":
          this.styleId = item.LABEL_ID;
      }
      this.getBuildingList();
    },
    getStyles() {
      const that = this;
      that.$api.getStyle({}).then((res) => {
        that.styleList = that.styleList.concat(res.data.splice(0, 7));
      });
    },
    getBuildingList() {
      const that = this;
      let para = {};
      para.min = that.min;
      para.max = that.max;
      para.houseType = that.houseType;
      para.styleId = that.styleId;
      para.pageNum = that.pageNum;
      para.keyWords = that.keyWords;
      that.$api.buildingList(para).then((res) => {
        that.buildingList = res.data.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/style.scss";
/deep/ .carousel-control-prev-icon {
  background-image: url(/icon/banner-left@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

/deep/ .carousel-control-prev {
  background: unset;
  border: unset;
  width: unset !important;
  margin-left: 1.5rem;
}

/deep/ .carousel-control-next-icon {
  background-image: url(/icon/banner-right@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

/deep/ .carousel-control-next {
  background: unset;
  border: unset;
  width: unset !important;
  margin-right: 1.5rem;
}
.buildPlan {
  background: #f2f2f2;
}

.a0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  .b0 {
    display: flex;
    padding: 0.72rem 0;
    width: 100%;
    .c0 {
      // display: flex;
      img {
        width: 1.67rem;
        height: 1.67rem;
      }
      span {
        font-size: 0.93rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3e3a39;
        padding-left: 1.06rem;
        padding-right: 3rem;
      }
    }
    .c1 {
      display: flex;
      .active {
        color: #f39800 !important;
      }
      .d0:nth-child(1) {
        border-left: 0.0625rem solid #707070;
      }
      .d0 {
        font-size: 0.81rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #595757;
        padding: 0 1.83rem;
        line-height: 2rem;
        cursor: pointer;
      }
    }
  }
}
.a01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 22.5rem 5rem;
  .b0 {
    // width: 75rem;
    display: flex;
    background: #ffffff;
    margin-top: 1.875rem;
    padding: 0.9375rem 0 0.9375rem 0;
    cursor: pointer;
    .c0 {
      .d0 {
        width: 35.2rem;
        height: 23.625rem;
      }
    }
    .c1 {
      .d0 {
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;
        .e0 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .f0 {
            font-size: 1.6875rem;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #221815;
          }
          .f1 {
            font-size: 15px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #221815;
          }
        }
        .e1 {
          .f0 {
            width: 4.0625rem;
            height: 4.0625rem;
            margin-right: 0.375rem;
          }
          .f1 {
            width: 4.0625rem;
            height: 4.0625rem;
          }
        }
      }
      .d1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 1.875rem;
        .e0 {
          font-size: 1.0625rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #221815;
        }
        .e1 {
          font-size: 1.0625rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #221815;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          overflow: hidden;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .a0 {
    padding: 1.02rem 0.5rem;
  }
  .a01 {
    padding: 0 0 5rem;
    .b0 {
      // width: 75rem;
      padding: 0 6.5rem 5rem;
    }
  }
}
@media (min-width: 992px) {
  .a0 {
    padding: 1.02rem 0.5rem;
  }
  .a01 {
    padding: 0 0 5rem;
    .b0 {
      // width: 75rem;
      // padding: 0 14.5rem 5rem;
      .c0 {
      .d0 {
        width: 50.2rem;
        height: auto;
      }
    }
    }
  }
}
@media (min-width: 1200px) {
  .a0 {
    padding: 1.02rem 22.5rem;
  }
  .a01 {
    padding: 0 22.5rem 5rem;
    .b0 {
     padding: 0.9375rem 0 0.9375rem 0;
    }
  }
}
// @media (max-width: 1200px) {
//   .a0 {
//     padding: 1.02rem 0;
//   }
// }
</style>